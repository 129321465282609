<template>
    <div id="carousel">
        <div id="carousel-wrapper">
            <Carousel></Carousel>
        </div>
    </div>
</template>

<script>
  import Carousel from "./components/Carousel.vue";

  export default {
    name: "carousel",
    components: {
      Carousel
    }
  };
</script>

<style lang="scss">
    #carousel {
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-content: center;
        #carousel-wrapper {
            position: absolute;
            right: 0;
            height: 80vh;
            width: 80vw;
            display: flex;
            flex-direction: row-reverse;
            align-content: center;
        }
    }
</style>
