<template>
    <div class="panel" ref="renderPanel">

    </div>
</template>

<script>
  import {
    Scene,
    GLTFLoader,
    WebGLRenderer,
    AnimationMixer,
    Camera,
    DoubleSide,
    SpriteMaterial,
    Sprite,
    Clock,
    Vector3
  } from "three-full";

  export default {
    name: "Carousel",
    data: function () {
      return {
        loader: new GLTFLoader(),
        renderer: new WebGLRenderer({alpha: true}),
        scene: new Scene(),
        mixer: false,
        loaded: false,
        clock: new Clock(),
        camera: new Camera(),
        objects: [],
        comRot: 0,
        EmptyObject: null,
        tvturm: null,
        angle: 0.07
      };
    },
    created() {
      this.renderer.setSize();
      this.renderer.setClearColor(0x000000, 0);

      this.loader.load(process.env.VUE_APP_GLTF_PATH, gltf => {
        this.scene = gltf.scene;
        this.camera = gltf.cameras[0];


        this.scene.traverse(node => {

          if (node.name == "Empty") {
            this.EmptyObject = node;
          }

          if (node.material && node.type == "Mesh") {
            //const material = new SpriteMaterial( { map: node.material.map, color: 0xffffff, fog: false } );
            //const sprite = new Sprite( material );
            //sprite.name = 'sprite_' + node.name;
            //node.add(sprite);
            //node.material.color = {r:1.0, g:0, b:0};
            //node.material.alphaMap = node.material.map;
            //node.material.map = null;
            node.flatShading = true;
            node.material.side = DoubleSide;
            node.material.map.premultiplyAlpha = false;
            node.material.needsUpdate = true;
            node.rotation.x = 0.0;
            node.rotation.y = 0.0;
            node.rotation.z = 0.0;

            if (node.name == 'tvturm') {
              this.tvturm = node;
            }
            this.objects.push(node);
          }
        });

        if (gltf.animations && gltf.animations.length) {
          this.mixer = new AnimationMixer(gltf.scene);
          for (let i = 0; i < gltf.animations.length; i++) {
            const animation = gltf.animations[i];
            animation.loop = false;
            const action = this.mixer.clipAction(animation);
            action.play();
          }
        }
        this.camera.updateProjectionMatrix();
        this.loaded = true;
      });
    },
    mounted() {

      window.addEventListener('resize', this.handleResize)
      this.renderer.setSize(
        this.$parent.$el.clientWidth,
        this.$parent.$el.clientHeight
      );

      const elem = this.$refs.renderPanel;
      elem.appendChild(this.renderer.domElement);
      this.animate();
      setTimeout(() => {
        this.handleResize();
      }, 180)
    },
    methods: {
      animate() {
        if (this.loaded) {
          if (this.mixer) {
            this.EmptyObject.rotateOnWorldAxis(new Vector3(0, 1, 0),this.angle)
            for(let i = 0; i < this.objects.length; i++) {
              let obj = this.objects[i];
              obj.rotateOnWorldAxis(new Vector3(0, 1, 0), -1 * this.angle)
            }
            this.renderer.render(this.scene, this.camera);
          }
        }

        setTimeout(() => {
          this.animate();
        }, 1000 / 7);


      },
      handleResize() {
        this.height = this.$parent.$el.clientHeight;
        this.width = this.$parent.$el.clientWidth;

        if (this.loaded) {
          this.camera.aspect = this.width / this.height;
          this.camera.updateProjectionMatrix();
          this.renderer.setSize(this.width, this.height);
        }
      }
    }
  };
</script>

<style lang="scss">
    .panel {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
    }
</style>
